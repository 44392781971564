<template>
    <b-card no-body class="mb-4 p-4">
        <div>
            <div class="grid border">
                <div class="bg-light" v-for="field in fields" :key="field.key">
                    <div class="p-2" style="font-weight: bold">{{ field.label }}</div>
                </div>
            </div>
            <div class="grid border" v-for="(item, idx) in items" :key="item.id">
                <div class="" v-for="field in fields" :key="field.key">
                    <div class="p-2">
                        <span v-if="field.key === 'action'">
                            <b-button v-b-toggle="`accordion-${idx}`" variant="primary" size="sm"
                                >Toggle Details</b-button
                            >
                        </span>
                        <span v-else>
                            <template v-if="item[field.key]">{{ item[field.key] }}</template>
                            <template v-else>N/A</template>
                        </span>
                    </div>
                </div>
                <b-collapse :id="`accordion-${idx}`" class="w-100" style="grid-column: span 8 / span 8">
                    <div class="px-3 pb-2">
                        <h6 class="mt-2 ml-2">Customers pending verification</h6>
                        <b-table
                            style="height: 400px"
                            :items="item.employees"
                            :fields="detailsFields"
                            small
                            responsive
                            bordered
                            head-variant="light"
                            hover
                        >
                            <template #cell(submitedToReviewDate)="data">{{
                                $formatDate(data.item.submitedToReviewDate)
                            }}</template>
                            <template #cell(fromDate)="data">{{ $formatDate(data.item.fromDate) }}</template>
                            <template #cell(toDate)="data">{{ $formatDate(data.item.toDate) }}</template>
                            <template #cell(isVerified)="data">
                                <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                                    <b-dropdown-item @click="goToCustomer(data.item)">Go to customer</b-dropdown-item>
                                    <b-dropdown-item @click="showEditModal(data.item)">Edit Employment</b-dropdown-item>
                                    <b-dropdown-item v-if="!data.item.isActive" @click="updateStatus(true, data.item)">
                                        Archive
                                    </b-dropdown-item>
                                    <b-dropdown-item v-else @click="updateStatus(false, data.item)">
                                        Active
                                    </b-dropdown-item>
                                </b-dropdown>
                            </template>
                        </b-table>
                    </div>
                </b-collapse>
            </div>
        </div>

        <b-modal
            id="modal-update-job-verification"
            title="Edit Employment"
            header-bg-variant="light"
            hide-footer
            centered
            @close="clearValues"
        >
            <b-form @submit.prevent="updateInfo" v-if="employerSelected">
                <b-card-text>
                    <b-form-group
                        label="Job Description"
                        label-for="job-description"
                        label-class="fw-medium"
                        class="mb-2"
                    >
                        <b-form-input
                            id="job-description"
                            type="text"
                            size="sm"
                            placeholder="Job Description"
                            v-model="employerSelected.jobDescription"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="From Date" label-for="from-date" label-class="fw-medium" class="mb-2">
                        <b-form-input
                            id="from-date"
                            type="date"
                            size="sm"
                            placeholder="From Date"
                            v-model="employerSelected.fromDate"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="To Date" label-for="to-date" label-class="fw-medium" class="mb-2">
                        <b-form-input
                            id="to-date"
                            type="date"
                            size="sm"
                            placeholder="To Date"
                            v-model="employerSelected.toDate"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Monthly Salary"
                        label-for="monthly-salary"
                        label-class="fw-medium"
                        class="mb-2"
                    >
                        <b-form-input
                            id="monthly-salary"
                            type="number"
                            size="sm"
                            placeholder="Monthly Salary"
                            v-model="employerSelected.salary"
                            required
                            min="1"
                        ></b-form-input>
                    </b-form-group>

                    <!-- Checkbox is verified -->
                    <b-form-checkbox
                        id="is-verified"
                        v-model="employerSelected.isVerified"
                        name="is-verified"
                        :value="true"
                        unchecked-value="false"
                        class="my-2"
                    >
                        Is Verified
                    </b-form-checkbox>

                    <!-- comments -->
                    <b-form-group label="Comments" label-for="comments" label-class="fw-medium" class="mb-2">
                        <b-form-textarea
                            id="comments"
                            size="sm"
                            placeholder="Comments"
                            v-model="employerSelected.verificationComments"
                        ></b-form-textarea>
                    </b-form-group>

                    <b-button block :disabled="loading" variant="primary" type="submit" class="mt-3">
                        <b-spinner small v-if="loading"></b-spinner>
                        <span v-else>Update Employment</span>
                    </b-button>
                </b-card-text>
            </b-form>
        </b-modal>
    </b-card>
</template>

<script>
import * as dayjs from 'dayjs';
export default {
    data() {
        return {
            filter: null,
            items: [],
            fields: [
                { key: 'businessID', label: 'Company ID' },
                { key: 'businessName', label: 'Company Name' },
                { key: 'businessPhone', label: 'Company Phone' },
                { key: 'businessEmail', label: 'Company Email' },
                { key: 'contactPersonName', label: 'Contact Person' },
                { key: 'contactPersonPhone', label: 'Contact Phone' },
                { key: 'contactPersonEmail', label: 'Contact Email' },
                { key: 'action', label: 'Action', isRowHeader: true },
            ],
            detailsFields: [
                { key: 'submitedToReviewDate', label: 'Date Submitted' },
                { key: 'nationalID', label: 'National ID' },
                { key: 'firstName', label: 'First Name' },
                { key: 'lastName', label: 'Last Name' },
                { key: 'jobDescription', label: 'Job Description' },
                { key: 'monthlySalary', label: 'Salary' },
                { key: 'fromDate', label: 'Start Date' },
                { key: 'toDate', label: 'End Date' },
                { key: 'isVerified', label: 'Actions' },
            ],
            employerSelected: {
                jobDescription: '',
                fromDate: '',
                toDate: '',
                salary: '',
                isVerified: false,
            },
            loading: false,
        };
    },
    methods: {
        async getData() {
            const response = await this.$store.dispatch('customer/employments/get_employmentJobVerifications');

            if (response) {
                this.items = response ?? [];
            }
        },
        formatDate(date) {
            if (!date) return;

            return dayjs(date).format('YYYY-MM-DD');
        },
        showEditModal(data) {
            this.employerSelected = data;
            this.employerSelected.fromDate = this.formatDate(data.fromDate);
            this.employerSelected.toDate = this.formatDate(data.toDate);
            this.$bvModal.show('modal-update-job-verification');
        },
        clearValues() {
            this.employerSelected = null;
        },
        async updateInfo() {
            this.loading = true;
            const response = await this.$store.dispatch(
                'customer/employments/update_employment',
                this.employerSelected,
            );

            if (response) {
                this.$bvModal.hide('modal-update-job-verification');
                this.getData();
            }

            this.loading = false;
        },
        async updateStatus(status, item) {
            let data = {
                id: item.id,
                isActive: status,
                modifiedBy: 1,
            };
            await this.$store.dispatch('customer/employments/update_employmentStatus', data);
            await this.getData();
        },
        goToCustomer(item) {
            this.$router.push(`/customers/list/${item.customerId}`);
        },
    },
    created() {
        this.getData();
    },
};
</script>

<style scoped>
.grid {
    display: grid;
    grid-template-columns: repeat(8, minmax(0, 1fr));
}
</style>
